import { mapActions, mapGetters } from "vuex";

import { MODALS } from "@/store/modules/modal";
import planSuggestionForm from "@/components/plan/plan-suggestion-form/plan-suggestion-form.vue";
import { getSegmentAmount } from "@/store/modules/plan";
import scrollTo from "@/helpers/scrollTo";
import questionTag from "@/components/question-tag/question-tag.vue";

export default {
  props: {
    plan: {
      type: Object,
      required: true,
    },
  },
  components: {
    planSuggestionForm,
    questionTag,
  },
  data() {
    return {
      isPaying: false,
      showSuggestionForm: false,
    };
  },
  computed: {
    ...mapGetters("app", ["isStripeEnabled"]),
    ...mapGetters("plan", [
      "totalPrice",
      "isCurrentPlanValid",
      "selectedNotSkippedSegments",
    ]),
    ...mapGetters("user", ["isLoggedIn"]),
    suggestionFormDescription() {
      return !this.plan.is_available
        ? "Proszę o aktualną propozycję wyjazdu"
        : "";
    },
  },
  methods: {
    ...mapActions("modal", ["setCurrentModal"]),
    ...mapActions("plan", [
      "payForPlan",
      "payForPlanV2",
      "setPlanFieldRequiredError",
    ]),
    ...mapActions("user", ["loginRequired"]),
    onPlanChange() {
      this.showSuggestionForm = !this.showSuggestionForm;
    },
    validateSegmentsExtraFields() {
      let isValid = true;
      let fieldToScrollTo = null;
      let fieldToScrollToSegment = null;
      const nonZeroValueSegments = this.selectedNotSkippedSegments.filter(
        (i) => !!getSegmentAmount(i)
      );
      nonZeroValueSegments.forEach((segment) => {
        segment.extra_fields.forEach((field, fieldIndex) => {
          if (field.required && !field.markup.selected) {
            isValid = false;
            this.setPlanFieldRequiredError({ segment, fieldIndex });
            if (fieldToScrollTo === null) {
              fieldToScrollTo = field;
              fieldToScrollToSegment = segment;
            }
          }
        });
      });

      if (fieldToScrollTo) {
        // We need to emit sectionIndex in order for page-plan-details to catch and activate this step
        // note that sections that come from api are numbered from 1 and in parent component
        // they are used in a regular array so currentStepIndex = sectionIndex - 1
        this.$emit("activateSection", {
          section: fieldToScrollToSegment.section,
        });
        this.$nextTick(() => {
          setTimeout(() => {
            const $el = document.querySelector(
              `div[data-field="${fieldToScrollTo.name}"]`
            );

            if ($el) {
              scrollTo($el.offsetTop - 50, 200, "linear");
            }
          }, 0);
        });
      }

      return isValid;
    },
    onProceedToPayment() {
      if (!this.isLoggedIn) {
        this.loginRequired();
        scrollTo(0);
        return;
      }
      const areRequiredFieldsValid = this.validateSegmentsExtraFields();
      if (!areRequiredFieldsValid) {
        return;
      }
      this.isPaying = true;
      const payload = {
        amount: Number(this.totalPrice.toFixed(2)),
        plan_data: this.plan,
      };
      const params = {
        planId: this.plan.id,
        payload,
      };

      if (this.isStripeEnabled) {
        this.payForPlanV2(params)
          .then((data) => {
            window.location.href = data.redirect_url;
          })
          .catch(() => {
            this.isPaying = false;
            this.setCurrentModal({
              name: MODALS.PLAN_ERROR,
            });
          });
      } else {
        this.payForPlan(params)
          .then((data) => {
            window.location.href = data.redirect_url;
          })
          .catch(() => {
            this.isPaying = false;
            this.setCurrentModal({
              name: MODALS.PLAN_ERROR,
            });
          });
      }
    },
  },
};
