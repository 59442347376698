import api from "@/services/api";

const LAYOUT_MOBILE = "mobile";
const LAYOUT_DESKTOP = "desktop";
const LAYOUT_SWITCH_BREAKPOINT = 640;

export default {
  namespaced: true,
  getters: {
    isLayoutMobile: (state) => state.layout === LAYOUT_MOBILE,
    isLayoutDesktop: (state) => state.layout === LAYOUT_DESKTOP,
    backRouter: (state) => state.backRouter || "home",
    areInspirationsEnabled: (state) => !!state.settings.INSPIRATIONS_ENABLED,
    areInspirationSeasonsEnabled: (state) =>
      !!state.settings.INSPIRATIONS_SEASONS_ENABLED,
    areAccountMyTripsEnabled: (state) =>
      !!state.settings.ACCOUNT_MY_TRIPS_ENABLED,
    areReviewsEnabled: (state) => !!state.settings.REVIEWS_ENABLED,
    isGoogleLoginEnabled: (state) => !!state.settings.GOOGLE_LOGIN_ENABLED,
    isFacebookLoginEnabled: (state) => !!state.settings.FACEBOOK_LOGIN_ENABLED,
    isStripeEnabled: (state) =>
      !!state.settings.PAYMENTS_STRIPE_ENABLED_OVER_CASHBILL,
    isInAppFBBrowser: () => window.navigator.userAgent.match(/FBAN|FBAV/i),
  },
  state: {
    loaderCounter: 0,
    layout: "",
    width: 0,
    backRouter: null,
    settings: {},
    reviews: [],
  },
  mutations: {
    setLayout(state, layout) {
      state.layout = layout;
    },
    setLayoutWidth(state, value) {
      state.width = value;
    },
    setBackRouter(state, router) {
      state.backRouter = router;
    },
    setSettings(state, data) {
      state.settings = data.reduce((obj, setting) => {
        obj[setting.name] = setting.value;
        return obj;
      }, {});
    },
    setReviews(state, data) {
      state.reviews = data;
    },
    incrLoaderCounter(state) {
      state.loaderCounter += 1;
    },
    decrLoaderCounter(state) {
      state.loaderCounter -= 1;
    },
  },
  actions: {
    setLayoutWidth({ commit }, width) {
      commit("setLayoutWidth", width);
      if (width >= LAYOUT_SWITCH_BREAKPOINT) {
        commit("setLayout", LAYOUT_DESKTOP);
      } else {
        commit("setLayout", LAYOUT_MOBILE);
      }
    },
    setBackRouter({ commit }, router) {
      commit("setBackRouter", router);
    },
    getSettings({ commit }) {
      return api.settings.getList().then((response) => {
        commit("setSettings", response.data.results);
      });
    },
    getReviews({ commit, state }, { cache = false } = {}) {
      if (cache && state.reviews.length > 0) {
        return Promise.resolve(state.reviews);
      }
      return api.advertising.getReviews().then((response) => {
        const { data } = response;
        commit("setReviews", data.results);
        return data.results;
      });
    },
    signupForNewsletter({ commit }, payload) {
      return api.advertising.signupForNewsletter(payload);
    },
    incrLoaderCounter({ commit }) {
      commit("incrLoaderCounter");
    },
    decrLoaderCounter({ commit }) {
      commit("decrLoaderCounter");
    },
  },
};
